import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

const URL_MAPPING = {
  PRIVACY_POLICY: {
    en: '/en/privacy-policy',
    fr: '/politique-vie-privee'
  },
  CONTACT_US: {
    en: '/en/contact-us',
    fr: '/contactez-nous'
  },
  FAQ: {
    en: '/en/faq',
    fr: '/faq'
  },
  TERMS: {
    en: '/en/terms',
    fr: '/termes'
  },
  PLANS_ADDONS: {
    en: '/en/plans-addons',
    fr: '/plans-et-ajouts'
  },
  APARTMENT_RENTAL: {
    en: '/en/apartment-rental',
    fr: '/location-appartement'
  },
  SETTINGS: {
    en: '/en/manage/settings',
    fr: '/manage/setting'
  },
  LIST_POST: {
    en: '/en/manage/list',
    fr: '/manage/list'
  },
  REVIEW_ADS: {
    en: '/en/manage/review-ads',
    fr: '/manage/review-ads'
  },
  LIST_USERS: {
    en: '/en/manage/list-users',
    fr: '/manage/list-users'
  },
  LISTS: {
    en: '/en/apartment-list',
    fr: '/liste-appartement'
  },
  OWNER: {
    en: '/en/owner',
    fr: '/proprietaire'
  },
  DETAIL_ADS: {
    en: '/en/detail',
    fr: '/detail'
  },
  BASE_URL: {
    en: '/en',
    fr: '/'
  }
};

@Injectable({ providedIn: 'root' })
export class UrlService {
  private static url: string;
  private static switchLang: boolean;

  private unsubscribe: Subscription[] = [];
  public urlLang = '';

  public privacyPolicy = URL_MAPPING.PRIVACY_POLICY.en;
  public contactUs = URL_MAPPING.CONTACT_US.en;
  public faq = URL_MAPPING.FAQ.en;
  public terms = URL_MAPPING.TERMS.en;
  public plansAddons = URL_MAPPING.PLANS_ADDONS.en;
  public apartmentRental = URL_MAPPING.APARTMENT_RENTAL.en;
  public baseUrl = URL_MAPPING.BASE_URL.en;
  public settings = URL_MAPPING.SETTINGS.en;
  public listPost = URL_MAPPING.LIST_POST.en;
  public reviewAds = URL_MAPPING.REVIEW_ADS.en;
  public listUsers = URL_MAPPING.LIST_USERS.en;
  public lists = URL_MAPPING.LISTS.en;
  public owner = URL_MAPPING.OWNER.en;
  public detailAds = URL_MAPPING.DETAIL_ADS.en;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  SetLang(language: string) {
    this.unsubscribe.push(
      this.translateService.use(language).subscribe({
        next: () => {
          const lang = this.translateService.currentLang;
          if (!lang) return;

          // Définir le préfixe d'URL en fonction de la langue
          this.urlLang = lang === 'en' ? '/en' : '';

          // Mise à jour des URLs en fonction de la langue
          this.privacyPolicy = URL_MAPPING.PRIVACY_POLICY[lang];
          this.contactUs = URL_MAPPING.CONTACT_US[lang];
          this.faq = URL_MAPPING.FAQ[lang];
          this.terms = URL_MAPPING.TERMS[lang];
          this.plansAddons = URL_MAPPING.PLANS_ADDONS[lang];
          this.apartmentRental = URL_MAPPING.APARTMENT_RENTAL[lang];
          this.baseUrl = URL_MAPPING.BASE_URL[lang];
          this.settings = URL_MAPPING.SETTINGS[lang];
          this.listPost = URL_MAPPING.LIST_POST[lang];
          this.reviewAds = URL_MAPPING.REVIEW_ADS[lang];
          this.listUsers = URL_MAPPING.LIST_USERS[lang];
          this.lists = URL_MAPPING.LISTS[lang];
          this.owner = URL_MAPPING.OWNER[lang];
          this.detailAds = URL_MAPPING.DETAIL_ADS[lang];

          const [currentURL, queryString] = UrlService.url.split('?');
          const partURL = [...currentURL.split('/')].filter((e) => e);
          let route = `/${partURL[0]}`;
          if (route.length <= 3 && partURL[1]) route += `/${partURL[1]}`;

          const queryParams = this.parseQueryParams(queryString);

          let url = this.mapUrlToLang(currentURL, lang);

          // Gestion spéciale pour les URLs de type detailAds
          if (
            currentURL.startsWith('/en/detail') ||
            currentURL.startsWith('/detail')
          ) {
            const id = partURL.at(-1);
            let slug = partURL.at(-3);
            slug += '/' + partURL.at(-2);

            if (lang === 'fr') {
              slug = slug
                .replace('apartment', 'appartement')
                .replace('room', 'chambre')
                .replace('house', 'maison')
                .replace('/en/', '/');
            } else {
              slug = slug
                .replace('appartement', 'apartment')
                .replace('chambre', 'room')
                .replace('maison', 'house');
            }

            url = `${this.detailAds}/${slug}/${id}`;
          } else if (
            UrlService.url.startsWith('/liste-appartement') ||
            UrlService.url.startsWith('/apartment-list') ||
            UrlService.url.startsWith('/en/apartment-list')
          ) {
            const id = partURL.at(-1);
            let slug = partURL.at(-2);
            if (
              !partURL.at(-3).includes('apartment-list') &&
              !partURL.at(-3).includes('liste-appartement')
            )
              slug = partURL.at(-3) + '/' + slug;

            if (lang === 'fr') {
              slug = slug
                .replace('apartment-list', 'liste-appartement')
                .replace('/en/', '/');
            } else {
              slug = slug.replace('liste-appartement', 'apartment-list');
            }
            url = `${this.lists}/${slug}/${id}`;
          }

          if (UrlService.switchLang && url !== UrlService.url) {
            this.activatedRoute.queryParams.pipe(take(1)).subscribe(() => {
              this.router.navigate([url], { queryParams });
            });
            UrlService.switchLang = false;
          }

          if (isPlatformBrowser(this.platformId)) {
            this.cookieService.set(
              '.AspNetCore.Culture',
              `c=${lang}|uic=${lang}`,
              {
                expires: 7,
                path: '/',
                secure: true,
                sameSite: 'Strict'
              }
            );
          }

          if (this.authenticationService.getJwt() !== '') {
            this.userService.setLanguage(lang).subscribe();
          }
        },
        complete: () => {
          this.unsubscribe.forEach((c) => c.unsubscribe());
          this.unsubscribe = [];
        }
      })
    );
  }

  private mapUrlToLang(currentURL: string, lang: string): string {
    for (const [key, value] of Object.entries(URL_MAPPING)) {
      if (currentURL.startsWith(value.en) || currentURL.startsWith(value.fr)) {
        return lang === 'en' ? value.en : value.fr;
      }
    }
    return URL_MAPPING.BASE_URL[lang];
  }

  private parseQueryParams(queryString: string): any {
    return (queryString ?? '').split('&').reduce((params: any, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
  }

  switchLang(lang: string = null) {
    const language =
      lang || (this.translateService.currentLang === 'fr' ? 'en' : 'fr');
    UrlService.url = this.location.path();
    UrlService.switchLang = true;
    this.SetLang(language);

    if (isPlatformBrowser(this.platformId)) {
      if (language === 'fr') this.document.documentElement.lang = 'fr-ca';
      else this.document.documentElement.lang = 'en';
    }
  }

  setLangLoad(lang: string) {
    const currentPath = this.location.path();
    const [pathWithoutQuery, queryString] = currentPath.split('?');

    UrlService.url = pathWithoutQuery;
    UrlService.switchLang = true;
    this.SetLang(lang);
  }
}
